// Core

body {

  .app-sidebar-wrapper-lg {
    min-height: 100%;
    overflowY: auto;
    display: flex;
    flex-direction: column;
    paddingTop: 0;
    width: $sidebar-width;
  }

  .app-sidebar-menu {
    height: 100%;
  }

  .app-sidebar-wrapper {
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    z-index: 60;
    position: static;
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  }

  .app-sidebar-wrapper-fixed {
    position: fixed;
  }

  .app-sidebar-wrapper-close {
    width: $sidebar-width-collapsed / 8;

    .sidebar-menu-children {
      display: none;
    }
  }

  .app-sidebar-wrapper-open {

    .sidebar-menu-children {
      display: block;
    }
  }

  .app-sidebar-nav-close {

    button span span {
      transition: $transition-base;
      white-space: nowrap;
      opacity: 0;
      margin-bottom: $spacer;
      visibility: hidden;
    }

    svg:first-child {
      transition: $transition-base;
      margin-right: 50px;
      margin-left: 17px;
    }

    .app-sidebar-heading {
      opacity: 0;
      overflow: hidden;
      white-space: nowrap;
      visibility: hidden;
      height: 44px;
    }
  }

  .app-sidebar-collapsed {
    .app-sidebar-footer-wrapper {
      visibility: hidden;
      opacity: 0;
    }
  }

  .app-sidebar-logo {
    width: 32px;
    height: 32px;
  }

  .app-submission-sidebar {
    min-height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    z-index: 60;
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    width: $sidebar-width;
  }

  .app-submission-sidebar-collapsed {
    width: auto;
  }

  .app-submission-content {
    min-height: 100%;
    overflow-y: auto;
    display: flex;
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    flex: 1;
  }

  .app-submission-body {
    height: 100%;
    width: 100%;
    display: block;
  }

  .app-preview-body {
    width: 100%;
    height: 100%;
    display: flex;
  }

  .app-preview-sidebar {
    width: $sidebar-width;
    height: 100%;
    overflow: hidden;
    background-color: #ffffff90;
    border-right: 1px solid #aaa;
  }

  .app-preview-content {
    flex: 1;
    height: 100%;
  }

  .app-submission-header {
    width: 100%;
    font-size: 1.3rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .app-survey-submit, .app-preview-image {
    background-image: url('../../images/hero-bg/fog-3622519_1920.jpg');
    background-color: #ffffff90;
    background-blend-mode: overlay;
  }
}
