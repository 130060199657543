.Container {
    display: block;
    padding: 1rem;
    width: calc(100% - 2rem);
    overflow-y: auto;
    background-color: #fafbfe;
    padding-bottom: 4rem;
  }
  
  .Sidebar {
    display: inline-block;
    width: 15%;
  }
  
  .Content {
    display: inline-block;
    width: 85%;
  }
  
  .OR {
    display: block;
    font-weight: 700;
    font-size: 1.5rem;
    color: #59a5d8;
    text-align: center;
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  
  .webcam {
    width: 30rem;
    margin: auto;
  }
  
  .Controls {
    display: block;
    width: 100%;
    text-align: center;
  }
  
  .Action {
    display: inline-block;
    padding: 1rem;
    margin: 1rem;
    cursor: pointer;
  }
  
  .Auth {
    display: block;
    width: 50%;
    margin: auto;
    margin-top: 5rem;
  }
  
  .options {
    display: block;
    padding: 0.45rem 0.9rem;
    width: calc(100% - 1rem);
  }
  
  .option-select {
    border: 1px solid #59a5d8;
    cursor: pointer;
    margin-left: 0.45rem;
    margin-right: 0.45rem;
    padding: 0.45rem 0.9rem;
    color: #59a5d8;
    width: 40%;
    display: inline-block;
    text-align: center;
    border-radius: 0.2rem;
    background-color: #dadbde;
    font-weight: 700;
  }
  
  .option-select.active {
    background-color: #59a5d8;
    color: #fafbfe;
  }