
  .likert-form-five .statement {
    display:block;
    font-size: 14px;
    font-weight: bold;
    padding: 30px 0 0 4.25%;
    margin-bottom:10px;
  }
  .likert {
    list-style:none;
    width:100%;
    margin:0;
    padding:0 0 35px;
    display:block;
    border-bottom:2px solid #efefef;
  }
  .likert-form-five.likert:last-of-type {border-bottom:0;}
  .likert-form-five .likert:before {
    content: '';
    position:relative;
    top:11px;
    left:9.5%;
    display:block;
    background-color:#b7b8bd;
    height:4px;
    width:76%;
  }
  .likert-form-five .likert li {
    display:inline-block;
    width:19%;
    text-align:center;
    vertical-align: top;
  }
  .likert-form-five .likert li input[type=radio] {
    display:block;
    position:relative;
    top:0;
    left:50%;
    margin-left:-6px;
    
  }

  .likert-form-seven .statement {
    display:block;
    font-size: 14px;
    font-weight: bold;
    padding: 30px 0 0 4.25%;
    margin-bottom:10px;
  }

  .likert-form-seven.likert:last-of-type {border-bottom:0;}
  .likert-form-seven .likert:before {
    content: '';
    position:relative;
    top:11px;
    left:6.5%;
    display:block;
    background-color:#efefef;
    height:4px;
    width:84%;
  }
  .likert-form-seven .likert li {
    display:inline-block;
    width:14%;
    text-align:center;
    vertical-align: top;
  }
  .likert-form-seven .likert li input[type=radio] {
    display:block;
    position:relative;
    top:0;
    left:50%;
    margin-left:-6px;
    
  }



  .likert-form-four .statement {
    display:block;
    font-size: 14px;
    font-weight: bold;
    padding: 30px 0 0 4.25%;
    margin-bottom:10px;
  }
  .likert-form-four .likert:last-of-type {border-bottom:0;}
  .likert-form-four .likert:before {
    content: '';
    position:relative;
    top:11px;
    left:11.5%;
    display:block;
    background-color:#efefef;
    height:4px;
    width:73%;
  }
  .likert-form-four .likert li {
    display:inline-block;
    width:24%;
    text-align:center;
    vertical-align: top;
  }
  .likert-form-four .likert li input[type=radio] {
    display:block;
    position:relative;
    top:0;
    left:50%;
    margin-left:-6px;
    
  }
  .likert-form-four .likert li label {width:100%;}
  .likert-form-seven .likert li label {width:100%;}
  .likert-form-five .likert li label {width:100%;}

