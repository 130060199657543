
@media  screen and (max-Height: 569px) {
    .design-height-side-pannel{
      height: 65vh;
    }
    .design-Height-gird{
        height: 65vh;
    }
    .pannel-hegiht{
        height: 65vh;
    }
}


@media  all and (min-Height: 569px) and (max-Height: 667px) {
    .design-height-side-pannel{
      height: 70vh;
    }
    .design-Height-gird{
        height: 70vh;
    }
}


@media  all and (min-Height: 667px) and (max-Height: 768px) {
    .design-height-side-pannel{
      height: 73vh;
    }
    .design-Height-gird{
        height: 73vh;
    }
}

@media  all and (min-Height: 768px) and (max-Height: 800px) {
    .design-height-side-pannel{
      height: 77vh;
    }
    .design-Height-gird{
        height: 77vh;
    }
    .pannel-hegiht{
        height: 110vh;
    }
}



@media  all and (min-Height: 800px) and (max-Height: 900px) {
    .design-height-side-pannel{
      height: 79vh;
    }
    .design-Height-gird{
        height: 79vh;
    }
    .pannel-hegiht{
        height: 110vh;
    }
}

@media  all and (min-Height: 900px) and (max-Height: 1440px) {
    .design-height-side-pannel{
      height: 82vh;
    }
    .design-Height-gird{
        height: 82vh;
    }
    .pannel-hegiht{
        height: 115vh;
    }
}

.side-overflow{
overflow-y: scroll;
height: 100%;
}
.side-overflow::-webkit-scrollbar {
-webkit-appearance: none;
width: 5px;
padding: 1px;
cursor: grab;
}
  
.side-overflow::-webkit-scrollbar-thumb {
border-radius: 3px;
background-color: rgba(197, 195, 195, 0.5);
-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
}

.side-overflowX{
overflow-x: scroll;
width:110%;
}
.side-overflowX::-webkit-scrollbar {
-webkit-appearance: none;
width: 5px;
padding: 1px;
cursor: grab;
}
    
.side-overflowX::-webkit-scrollbar-thumb {
border-radius: 3px;
background-color: rgba(197, 195, 195, 0.5);
-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
}
